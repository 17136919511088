<template>
  <div>
    <div v-if="model">
      <iframe
        v-if="isYoutubeLink"
        type="text/html"
        :src="getVideoSrc"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <!-- IPTV Link Handling -->
      <video
        v-else-if="isIPTVLink"
        ref="videoPlayer"
        :muted="isIPTVMuted"
        class="fixed-video"
        poster="/images/bg/transparent.png"
        autoplay
        @canplay="videoLoaded"
        @waiting="videoLoading"
      >
        Your browser does not support the video tag.
      </video>

      <div class="" v-else-if="model.youtubeLink">
        <iframe
          :src="model.youtubeLink"
          width="100%"
          height="100%"
          style="position: fixed; z-index: 9999"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
      </div>
    </div>
    <div v-if="isLoading" class="loading-indicator"><Loading /></div>
  </div>
</template>

<script>
import Hls from "hls.js";
import Loading from "@/components/common/Loading.vue";
export default {
  name: "youtube",
  props: ["data"],
  components: { Loading },
  data() {
    return {
      isIPTVMuted: true,
      isLoading: false,
    };
  },
  computed: {
    model() {
      return this.$store.state;
    },
    isYoutubeLink() {
      let link = this.model.youtubeLink;
      window.console.log("youtube link:", link);

      if (link && link.length) {
        // check if link belongs to youtube or not if not show web page in object element
        if (
          link.toLowerCase().indexOf("youtube") !== -1 ||
          link.toLowerCase().indexOf("youtu") !== -1
        ) {
          return true;
        }
      }
      return false;
    },
    isIPTVLink() {
      window.console.log("iptv link:", this.model.youtubeLink);
      return this.model.youtubeLink && this.model.youtubeLink.includes(".m3u8");
    },
    getVideoSrc() {
      let youtubeVideoId = "",
        youtubePlaylistId = "";
      let isMute = 1;
      let link = this.model.youtubeLink;
      if (link && link.length) {
        if (
          link.toLowerCase().indexOf("youtube") === -1 &&
          link.toLowerCase().indexOf("youtu") === -1
        ) {
          //https://youtu.be/Ictef4z9Nl0
          return;
        }
        if (link.indexOf("hasVoice") !== -1) {
          isMute = 0;
          link = link.replace("hasVoice", "");
        }
        if (link.indexOf("youtu.be") !== -1) {
          var queryStr = link.split("?");
          if (queryStr && queryStr.length) {
            link = queryStr[0];
          }
          var splittedLink = link.split("/");

          youtubeVideoId = splittedLink[splittedLink.length - 1];
        } else {
          var queryString = link.split("?")[1];
          const urlParams = new URLSearchParams(queryString);
          youtubeVideoId = urlParams.get("v");
          youtubePlaylistId = urlParams.get("list");
        }
      }

      if (youtubePlaylistId && youtubePlaylistId.length)
        return `https://www.youtube.com/embed/videoseries?loop=1&mute=${isMute}&list=${youtubePlaylistId}&controls=0&showinfo=0&autoplay=1`;
      return `https://www.youtube.com/embed/${youtubeVideoId}?rel=0&modestbranding=1&autohide=1&mute=${isMute}&showinfo=0&controls=0&loop=1&autoplay=1&playlist=${youtubeVideoId}`;
    },
  },
  mounted() {
    this.initializeVideo();
  },
  watch: {
    "model.link": {
      handler() {
        this.initializeVideo();
      },
    },
  },
  methods: {
    initializeVideo() {
      if (this.isIPTVLink && this.$refs.videoPlayer) {
        this.isLoading = true;
        let link = this.model.youtubeLink;
        if (link.indexOf("hasVoice") !== -1) {
          this.isIPTVMuted = false;
          link = link.replace("hasVoice", "");
        } else {
          this.isIPTVMuted = true;
        }
        if (Hls.isSupported()) {
          var hls = new Hls();
          hls.loadSource(link);
          hls.attachMedia(this.$refs.videoPlayer);
        } else if (
          this.$refs.videoPlayer.canPlayType("application/vnd.apple.mpegurl")
        ) {
          this.$refs.videoPlayer.src = link;
          this.$refs.videoPlayer.play();
        }
      } else {
        // Clear the video source or handle non-m3u8 links
        this.clearVideo();
      }
    },
    clearVideo() {
      if (this.$refs.videoPlayer) {
        this.isLoading = false;
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.src = "";
      }
    },
    videoLoaded() {
      this.isLoading = false;
    },
    videoLoading() {
      this.isLoading = true;
    },
  },
};
</script>

<style lang="scss">
iframe {
  position: fixed;
  right: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}
.fixed-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  z-index: 1000;
}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
}
</style>
